import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./style.scss";
import { QueryClient, QueryClientProvider, useQuery } from "@tanstack/react-query";
import { Fragment } from "react";
import { useBlogApi } from "../../hooks/useBlogApi";

const responsive = {
  4: {
    breakpoint: { max: 10000, min: 2000 },
    items: 4,
  },
  3: {
    breakpoint: { max: 2000, min: 1200 },
    items: 3,
  },
  2: {
    breakpoint: { max: 1200, min: 768 },
    items: 2,
  },
  1: {
    breakpoint: { max: 768, min: 0 },
    items: 1,
  },
};

const queryClient = new QueryClient();

const PortfolioCarousel = () => {
  const { getPortfolioProjects } = useBlogApi();

  const { data: projects } = useQuery({
    queryKey: ["FETCH_PORTFOLIO_PROJECTS"],
    queryFn: getPortfolioProjects,
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });

  return (
    projects && (
      <Carousel
        responsive={responsive}
        infinite
        autoPlay
        autoPlaySpeed={5000}
        transitionDuration={500}
        showDots
        swipeable
        draggable
        dotListClass="dots"
        renderDotsOutside
      >
        {projects.map((p) => (
          <div className="item" key={p.id}>
            <div className="logo">
              <img src={p.logoUrl} alt={`${p.name} project logo`} />
            </div>
          </div>
        ))}
      </Carousel>
    )
  );
};

const PortfolioCarouselContext = () => {
  return (
    <Fragment>
      <QueryClientProvider client={queryClient}>
        <PortfolioCarousel />
      </QueryClientProvider>
    </Fragment>
  );
};

export default PortfolioCarouselContext;
